/* $theme-colors: (
  'primary': #212529
); */
/* $theme-colors: (
  'primary': #e9722e
); */

/* $body-bg: #111; */

$form-check-input-checked-bg-color: #212529;

@import 'node_modules/bootstrap/scss/bootstrap';

/* $nav-pills-link-active-color: #e9722e;

$nav-tabs-link-active-color: purple;

$nav-link-color: green; */

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a span:hover i {
  text-decoration: underline;
}

.navbar a:hover {
  text-decoration: none;
}

a.footer-links {
  color: #33d1ff;
  text-decoration: none;
  background-color: transparent;
}

a.footer-links:hover {
  /* color: #337ab7; */
  color: #33d1ff;
  text-decoration: underline;
}

.text-fitkit {
  color: #e9722e;
}

.landing-links {
  background-color: rgb(68, 66, 66);
  padding: 10px;
}

.landing-links h3 {
  font-size: 20px;
  font-weight: bold;
  color: #e9722e;
}

.landing-links p {
  margin-bottom: 0;
}

a.landing-links-link {
  color: white;
  text-decoration: none;
  background-color: transparent;
}

a.landing-links-link:hover {
  /* color: #337ab7; */
  color: white;
  text-decoration: underline;
}

.brandImage {
  height: 35px;
  width: 35px;
  margin-right: 5px;
}

.alertDiv {
  min-height: 46px;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;

  /*  border: dotted red; */
}

.contentDiv {
  margin-bottom: 100px;
}

.headerDiv {
  height: 185px;
  /*  border: dotted green; */
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  background-color: white;
}
.smallHeaderDiv {
  height: 85px;
  /*  border: dotted green; */
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  background-color: white;
}

.subHeaderDiv {
  height: 65px;
  /*  border: dotted blue; */
  position: fixed;
  top: 125;
  right: 0;
  left: 0;
  z-index: 1000;
}

.footerPin {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}

div.container {
  max-width: 1010px;
}

.alert {
  max-height: 46px;
  margin: 0, 0, 0, 0;
}

.fks-ol {
  padding-left: 1.2rem;
}

.noButtonOutline {
  box-shadow: none !important;
  outline: none !important;
  outline-offset: none !important;
  text-decoration: none;
}

.nav-pills .nav-link.active {
  background-color: #e9722e;
  text-decoration: none;
}

.nav-pills .nav-link {
  text-decoration: none;
  color: black;
  cursor: pointer;
}

.bikeFormModal .modal-dialog-centered {
  min-width: 600px;
}

.card-text-size {
  font-size: 0.8rem;
}

.highlightChanges {
  background-color: #e9722e !important;
}

.bikeSelect select:focus {
  border-style: inset;
  border-color: gray;
  outline: none;
}

hr.fksHR {
  border-top: 5px solid #e9722e;
}

.bodyPad {
  padding-top: 100px !important;
}

.preFitBodyPad {
  padding-top: 25px !important;
}

.landingBodyPad {
  padding-top: 110px !important;
}

@media screen and (min-width: 600px) {
  .userForms {
    max-width: 40%;
    /* background-color: pink; */
  }

  body {
    /* padding-top: 64px; */
    padding-top: 190px;
  }
}

@media screen and (max-width: 768px) {
  /*   body {
    padding-top: 220px;
  } */

  .mobile-padding {
    padding-top: 145px;
  }

  .bikeFormModal .modal-dialog-centered {
    min-width: 10px;
  }
}
